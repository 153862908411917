<template>
    <div>
        <b-overlay :show="!dataLoaded">
            <b-card title="General config">
                <b-col cols="6" class="pl-0">
                    <b-form-group>
                        <label>Sales history product category</label>
                        <v-select
                            v-model="config.sales_history_product_category"
                            :reduce="type => type.id"
                            label="name"
                            :options="productCategories"
                        />
                    </b-form-group>
                </b-col>
                <b-col cols="6" class="pl-0">
                    <b-form-group>
                        <label>Sales history product divider</label>
                        <b-form-input  v-model="config.sales_history_product_divider"/>
                    </b-form-group>
                </b-col>
                <b-col cols="6" class="pl-0">
                    <b-form-group>
                        <label>Sales history unit</label>
                        <b-form-input  v-model="config.sales_history_unit"/>
                    </b-form-group>
                </b-col>
                <b-col cols="6" class="pl-0">
                    <b-form-group>
                        <label>GeoFence distance</label>
                        <b-form-input  v-model="config.geo_fence_distance"/>
                    </b-form-group>
                </b-col>
                <hr/>
                <b-button variant="primary" @click="saveConfig">
                    <feather-icon
                        icon="SaveIcon"
                        class="mr-50"
                    />
                    <span class="align-middle">Save config</span>
                </b-button>
            </b-card>
        </b-overlay>
    </div>
</template>
<script>

    import {BCard, BButton, BFormInput, BFormGroup, BCol, BOverlay} from 'bootstrap-vue'
    import vSelect from 'vue-select'

    export default {
        components: {
            BCard,
            BOverlay,
            BButton,
            BFormInput,
            BFormGroup,
            BCol,
            vSelect
        },
        data() {
            return {
                dataLoaded: false,
                config: {
                    sales_history_product_category: '',
                    sales_history_product_divider: '',
                    geo_fence_distance: '',
                    sales_history_unit: ''
                },
                productCategories: []
            }
        },
        methods: {
            loadData() {
                this.dataLoaded = false
                const thisIns = this
                const loadPromise = this.$http.get('/api/management/v1/config/general_config')
                loadPromise.then(function(response) {
                    thisIns.config = response.data
                }).catch(function(error) {
                    thisIns.$printError((error.response) ? error.response.data : error)
                })

                const productCategoriesloadPromise = this.$http.get('/api/management/v1/product_category')
                productCategoriesloadPromise.then(function(response) {
                    thisIns.productCategories = response.data
                }).catch(function(error) {
                    thisIns.$printError((error.response) ? error.response.data : error)
                })


                Promise.all([loadPromise, productCategoriesloadPromise]).finally(function() {
                    thisIns.dataLoaded = true
                })
            },
            saveConfig() {
                const thisIns = this
                const loadPromise = this.$http.put('/api/management/v1/config/general_config', thisIns.config)
                loadPromise.then(function() {
                    thisIns.$printSuccess('Config saved')
                }).catch(function(error) {
                    thisIns.$printError((error.response) ? error.response.data : error)
                })
            }
        },
        mounted() {
            this.loadData()
        }
    }
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>